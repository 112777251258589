import { scrollToTop } from 'src/infrastructure/utils';
import { OnDestroy, AfterViewInit } from '@angular/core';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { Configuration } from 'src/infrastructure/configuration/configuration';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { faCaretLeft } from '@fortawesome/free-solid-svg-icons';
import { takeUntil } from 'rxjs/operators';

export class PagesBaseComponent implements AfterViewInit, OnDestroy {
  constructor(
    private translateService: TranslateService,
    private router: Router,
    private route: ActivatedRoute,
    config: Configuration,
    sanitizer: DomSanitizer
  ) {
    this.translateService.onLangChange
      .pipe(takeUntil(this.destroyed$))
      .subscribe((e: LangChangeEvent) => {
        this.currentLang = e.lang;
        this.router.navigate(['/' + e.lang, this.componentUrl], { replaceUrl: true });
      });

    this.route.fragment
      .pipe(takeUntil(this.destroyed$))
      .subscribe(fragment => {
        console.log(fragment);
        this.fragment = fragment;
      });

    this.loginUrl = sanitizer.bypassSecurityTrustUrl(`${config.internalAppUrl}/login`);
    this.currentLang = translateService.currentLang;
  }

  destroyed$: Subject<boolean> = new Subject<boolean>();
  componentUrl: string = '';
  loginUrl: SafeUrl;
  currentLang: string = '';
  fragment: string = null;
  backIcon = faCaretLeft;

  ngAfterViewInit() {
    if (this.fragment) {
      const anchoredSection = document.querySelector(`#${this.fragment}`);
      if (anchoredSection)
        anchoredSection.scrollIntoView();
    }
    else {
      scrollToTop();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.unsubscribe();
  }

  back() {
    window.history.back();
  }
}